<template>
  <div class="role-manage">
    <div class="search-area">
      <el-form size="small" :inline="true" :model="searchParams" class="">
        <el-form-item label="角色名称：">
          <el-input
            v-model="searchParams.name"
            placeholder="请输入角色名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-show="authorities.includes('BACK:BACK_T_ROLE_PAGE_LIST')" type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="opt-area">
      <el-button
        v-show="authorities.includes('BACK:BACK_T_ROLE_ADD')"
        size="small"
        @click="handleCreateRole"
        type="primary"
        icon="el-icon-plus"
        >新增角色</el-button
      >
    </div>
    <div class="list-area">
      <el-table
        v-show="authorities.includes('BACK:BACK_T_ROLE_PAGE_LIST')"
        height="100%"
        :data="roleList"
        v-loading="loading"
        row-key="id"
        style="width: 100%; min-width: 1200px"
        :header-cell-style="{ background: '#eef1f6' }"
      >
        <el-table-column prop="id" align="center" label="ID" width="180">
        </el-table-column>
        <el-table-column prop="name" align="center" label="角色名称">
        </el-table-column>
        <el-table-column prop="roleRemark" align="center" label="角色说明">
        </el-table-column>
        <el-table-column prop="enable" align="center" label="状态" width="80">
          <template slot-scope="scope">
            <el-link :underline="false" type="primary" v-if="scope.row.enable == 1">有效</el-link>
            <el-link :underline="false" v-else type="danger">无效</el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="updateTimeString"
          align="center"
          label="更新时间"
        >
        </el-table-column>
        <el-table-column prop="address" align="center" label="操作">
          <template slot-scope="scope">
            <el-link
            v-show="authorities.includes('BACK:BACK_T_ROLE_UPDATE')"
              type="primary"
              :underline="false"
              @click="handleEditRole(scope.row)"
              >修改</el-link
            >
            <el-link
              v-show="authorities.includes('BACK:BACK_T_ROLE_ALL_MENU')"
              type="primary"
              class="m-l-10"
              :underline="false"
              @click="handleAuthRole(scope.row)"
              >授权管理</el-link
            >
            <el-link
              type="primary"
              class="m-l-10"
              v-show="scope.row.enable == 1 && authorities.includes('BACK:BACK_T_ROLE_DISABLE')"
              :underline="false"
              @click="handleEnbleOrDisableRole(scope.row)"
              >禁用</el-link
            >
            <el-link
              type="primary"
              class="m-l-10"
              v-show="scope.row.enable == 0 && authorities.includes('BACK:BACK_T_ROLE_DISABLE')"
              :underline="false"
              @click="handleEnbleOrDisableRole(scope.row)"
              >启用</el-link
            >
            <el-link
              v-show="authorities.includes('BACK:BACK_T_ROLE_DELETE')"
              type="primary"
              class="m-l-10"
              :underline="false"
              @click="handleDeleteRole(scope.row)"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="bottom-area">
      <el-pagination
        v-show="authorities.includes('BACK:BACK_T_ROLE_PAGE_LIST')"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchParams.pageNum"
        :page-size="searchParams.pageSize"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="dialogAddRoleTitle"
      :visible.sync="dialogAddRole"
      width="500px"
    >
      <div>
        <el-form
          ref="saveRoleData"
          size="small"
          :model="saveRoleData"
          :rules="rules"
          label-width="100px"
        >
          <el-form-item label="角色名称：" prop="name">
            <el-input
              v-model="saveRoleData.name"
              placeholder="请输入角色名称"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="角色说明：" prop="roleRemark">
            <el-input
              type="textarea"
              :rows="2"
              resize="none"
              placeholder="请输入角色说明"
              maxlength="200"
              show-word-limit
              v-model="saveRoleData.roleRemark"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="是否有效：">
            <el-radio-group v-model="saveRoleData.enable">
              <el-radio :label="1">有效</el-radio>
              <el-radio :label="0">无效</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogAddRole = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleConfirmAddRole"
          >保 存</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="角色权限设置"
      :visible.sync="dialogRoleAuthSetting"
      width="500px"
    >
      <div>
        <el-tree
          :data="menuList"
          show-checkbox
          node-key="id"
          ref="tree"
          default-expand-all
          :default-checked-keys="defalutCheckedList"
          :props="defaultProps"
          @check="handleCheckChange"
        >
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogRoleAuthSetting = false"
          >取 消</el-button
        >
        <el-button
          v-show="authorities.includes('BACK:BACK_T_UPDATE_ROLE_MENU')"
          size="small"
          type="primary"
          @click="handleConfirmRoleAuthSetting"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  sysRoleMenus,
  sysRoleDelete,
  sysRoleDisable,
  sysRoleAdd,
  sysRoleList,
  sysRoleUpdate,
  sysRoleMenuUpdate,
} from "@/api/sysRole";
import { mapState } from 'vuex';
export default {
  data() {
    return {
      searchParams: {
        name: "",
        pageNum: 1,
        pageSize: 10,
      },
      defaultProps: {
        children: "children",
        label: "name",
      },
      menuIds: [],
      defalutCheckedList: [],
      loading: false,
      roleList: [],
      total: 0,
      editRoleId: 0,
      dialogAddRoleTitle: "",
      dialogAddRole: false,
      dialogRoleAuthSetting: false,
      menuList: [],
      saveRoleData: {
        enable: 1,
        name: "",
        roleRemark: "",
      },
      rules: {
        name: [
          { required: true, message: "角色名称不能为空", trigger: "change" },
        ],
        roleRemark: [
          { required: true, message: "角色说明不能为空", trigger: "change" },
        ],
        enable: [
          { required: true, message: "是否有效不能为空", trigger: "change" },
        ],
      },
    };
  },
  components: {},
  computed: {
    ...mapState(['authorities']),
  },
  created() {
    this.getSysRoleList();
  },
  mounted() {},
  methods: {
    /* 点击搜索按钮 */
    handleSearch() {
      this.searchParams.pageNum = 1;
      this.getSysRoleList();
    },
    /* 获取角色列表 */
    async getSysRoleList() {
      if(!this.authorities.includes('BACK:BACK_T_ROLE_PAGE_LIST')){
        return
      }
      try {
        this.loading = true;
        let { records, total, pages, current } = await sysRoleList(this.searchParams);
        this.loading = false;
        if (!records) {
          records = [];
        }
        this.roleList = records;
        this.total = total;
        if(current > pages && pages) {
          this.searchParams.pageNum = pages 
          this.getSysRoleList()
        }
      } catch (err) {
        this.loading = false;
        let msg = err.msg ? err.msg : "获取角色列表发生异常";
        console.error("sysRoleList err: ", err);
        this.msgError(msg);
      }
    },
    /* 点击创建角色 */
    handleCreateRole() {
      this.dialogAddRoleTitle = "新增角色";
      this.dialogAddRole = true;
      this.$nextTick(() => {
        this.$refs['saveRoleData'].resetFields()
      })
    },
    /* 点击确定保存新增或者更新 */
    async handleConfirmAddRole() {
      this.$refs["saveRoleData"].validate(async (valid) => {
        if (valid) {
          let optName = "";
          if(this._addOrEditFlag){
            return
          }
          this._addOrEditFlag = true
          try {
            if (this.dialogAddRoleTitle == "新增角色") {
              optName = "新增";
              await sysRoleAdd(this.saveRoleData);
            } else if (this.dialogAddRoleTitle == "修改角色") {
              optName = "修改";
              await sysRoleUpdate({
                ...this.saveRoleData,
                id: this.editRoleId,
              });
            }
            this._addOrEditFlag = false
            this.dialogAddRole = false;
            this.getSysRoleList();
            this.msgSuccess(`${optName}成功`);
          } catch (err) {
            let msg = err.msg ? err.msg : `${optName}角色发生异常！`;
            console.error("roleAdd err: ", err);
            this.msgError(msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /* 当前页改变 */
    handleCurrentChange(pageNum) {
      this.searchParams.pageNum = pageNum;
      this.getSysRoleList();
    },
    /* 每页条数改变 */
    handleSizeChange(pageSize) {
      this.searchParams.pageSize = pageSize;
      this.getSysRoleList();
    },
    /* 处理点击修改 */
    handleEditRole(item) {
      const { id, enable, name, roleRemark } = item;
      this.editRoleId = id;
      this.saveRoleData = {
        enable,
        name,
        roleRemark,
      };
      this.dialogAddRoleTitle = "修改角色";
      this.dialogAddRole = true;
    },
    /* 处理点击授权管理 */
    async handleAuthRole(item) {
      const { id } = item;
      this.dialogRoleAuthSetting = true;
      this.defalutCheckedList = [];
      this.editRoleId = id;
      try {
        let menuList = await sysRoleMenus({
          roleId: id,
        });
        if (!menuList) {
          menuList = [];
        }
        this.menuList = menuList;
        this.formatRoleAuthData(this.menuList);
      } catch (err) {
        let msg = err.msg ? err.msg : "获取权限设置列表发生异常";
        console.error("sysRoleMenus err: ", err);
        this.msgError(msg);
      }
    },
    /* 处理禁用启用 */
    async handleEnbleOrDisableRole(item) {
      const { enable, id } = item;
      if (enable == 1) {
        this.$prompt("请输入禁用原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputValidator: (value) => {
            if (!value || !value.trim()) {
              return "封禁原因不能为空！";
            }
            return true;
          },
        })
          .then(async ({ value }) => {
            try {
              await sysRoleDisable(id, 0, value);
              this.msgSuccess("封禁成功！");
              this.getSysRoleList();
            } catch (err) {
              let msg = err.msg ? err.msg : "封禁发生异常！";
              console.error("sysRoleDisable: ", err);
              this.msgError(msg);
            }
          })
          .catch(() => {});
      } else {
        try {
          await sysRoleDisable(id, 1);
          this.msgSuccess("解除封禁成功！");
          this.getSysRoleList();
        } catch (err) {
          let msg = err.msg ? err.msg : "解除封禁发生异常！";
          console.error("sysRoleDisable: ", err);
          this.msgError(msg);
        }
      }
    },
    /* 处理删除 */
    handleDeleteRole(item) {
      const { id } = item;
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            await sysRoleDelete(id);
            this.msgSuccess("删除成功");
            this.getSysRoleList();
          } catch (err) {
            let msg = err.msg ? err.msg : "角色删除发生异常！";
            console.error("sysRoleDelete err: ", err);
            this.msgError(msg);
          }
        })
        .catch(() => {});
    },
    /* 更新角色菜单 */
    async handleConfirmRoleAuthSetting() {
      if(this._AuthSettingFlag){
        return
      }
      this._AuthSettingFlag = true
      try {
        await sysRoleMenuUpdate({
          roleId: this.editRoleId,
          menuIds: this.menuIds,
        });
        this._AuthSettingFlag = false
        this.dialogRoleAuthSetting = false;
        this.msgSuccess("保存成功！");
      } catch (err) {
        this._AuthSettingFlag = false
        let msg = err.msg ? err.msg : "保存角色权限设置发生异常！";
        console.error("sysRoleMenuUpdate err: ", err);
        this.msgError(msg);
      }
    },
    /* 角色权限tree选中 */
    handleCheckChange(v, e) {
      this.menuIds = this.$refs.tree
        .getCheckedKeys()
        .concat(this.$refs.tree.getHalfCheckedKeys());
    },
    /* 格式化角色权限数据 */
    formatRoleAuthData(list) {
      list.forEach((item) => {
        if (item.checked == true) {
          this.defalutCheckedList.push(item.id);
        }
        if (item.children && item.children.length > 0) {
          this.formatRoleAuthData(item.children);
        }
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.role-manage {
  height: 100%;
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .search-area {
    font-size: 12px;
    padding: 0 10px 0;
    text-align: left;
  }
  .opt-area {
    padding: 0 10px 10px;
    text-align: right;
  }
  .list-area {
    flex: 1;
    overflow-x: auto;
    .m-l-10 {
      margin-left: 10px;
    }
  }
  .bottom-area {
    height: 40px;
    text-align: center;
  }
}
</style>